import Head from 'next/head'
import SectionContainer from './SectionContainer'
import classNames from 'classnames'
import { maxBreakpoints } from 'constants/viewport'
import { APattern } from 'components/Svgs/APattern.js'
import { MediaImageStandard } from 'components/Contentful'
import { rewriteAssetUrl } from 'utils/conversions'

const bgBlue = 'blue'
const bgDefault = 'bgDefault'
const bgPrimaryHighlightBlue = 'bgPrimaryHighlightBlue'
const bgShadowPrimaryHighlightBlue = 'bgShadowPrimaryHighlightBlue'
const bgShadowPrimaryHighlightWhiteBlue = 'bgShadowPrimaryHighlightWhiteBlue'
const blueGradient1 = 'blueGradient1'
const neutralGrey8 = 'neutralGrey8'
const purpleBlueGradient1 = 'purpleBlueGradient1'
const white = 'white'
const achieveHighlightBlue = 'achieveHighlightBlue'
const bgBlue100 = 'blue100'
const bgBlue25 = 'blue25'

export const SECTION_VARIANTS = {
  bgBlue,
  bgPrimaryHighlightBlue,
  bgDefault,
  bgShadowPrimaryHighlightBlue,
  bgShadowPrimaryHighlightWhiteBlue,
  blueGradient1,
  purpleBlueGradient1,
  neutralGrey8,
  white,
  achieveHighlightBlue,
  bgBlue100,
  bgBlue25,
}

function formatMediaUrl(mediaContentURL) {
  return mediaContentURL && !mediaContentURL.includes('.svg')
    ? `${mediaContentURL}?fm=webp&q=50`
    : mediaContentURL
}

function Section({
  children,
  defaultPadY = false,
  backgroundColor,
  backgroundImageAlt = '',
  mediaContent,
  middleMediaContent,
  mobileMediaContent,
  backgroundObjectPosition = 'top',
  childContainerClassName,
  className,
  id,
  sectionStyles,
  showPattern = false,
  contain = true,
  variant = bgDefault,
  preloadBackground = false,
  backgroundNoCss = false,
  full = false,
  ...restProps
}) {
  const hasBg = Boolean(mediaContent || middleMediaContent || mobileMediaContent)

  // This handles images from both GraphQA (flat) or rest api structure
  let mobileMediaUrl = mobileMediaContent?.url ?? mobileMediaContent?.fields?.file?.url ?? null
  let middleMediaUrl = middleMediaContent?.url ?? middleMediaContent?.fields?.file?.url ?? null
  let mediaUrl = mediaContent?.url ?? mediaContent?.fields?.file?.url ?? null

  mobileMediaUrl = formatMediaUrl(mobileMediaUrl)
  middleMediaUrl = formatMediaUrl(middleMediaUrl)
  mediaUrl = formatMediaUrl(mediaUrl)

  const contents = contain ? (
    <SectionContainer className={childContainerClassName}>{children}</SectionContainer>
  ) : (
    children
  )

  return (
    <section
      id={id}
      style={{
        // TODO: lets remove explicit backgroundColor and just allow a style object to be passed
        // leaving the responsibility of custom css to be accurately verified by CMS user.
        backgroundColor,
        ...sectionStyles,
      }}
      className={classNames(
        // styles['achieve-section'],
        // className
        'relative data-[default-pad-y="true"]:py-12 data-[default-pad-y="true"]:lg:py-16',
        // 'data-[variant="bgDefault"]:'
        'data-[variant="blueGradient1"]:blueGradient1',
        'data-[variant="purpleBlueGradient1"]:purpleBlueGradient1',
        'data-[variant="neutralGrey8"]:bg-neutral-25',
        'data-[variant="achieveHighlightBlue"]:bg-[#eff5ff]',
        'data-[variant="blue25"]:bg-[#f5f9ff]',
        'data-[variant="blue"]:bg-[#3300ff]',
        'data-[variant="blue100"]:bg-[#d2e2fe]',
        'data-[variant="bgPrimaryHighlightBlue"]:bg-[#eff5ff]',
        'data-[variant="white"]:bg-white',
        'data-[variant="bgPrimaryHighlightBlue2"]:bg-[#f5f9ff]',
        'data-[variant="bgShadowPrimaryHighlightBlue"]:bgShadowPrimaryHighlightBlue',
        'data-[variant="bgShadowPrimaryHighlightWhiteBlue"]:bgShadowPrimaryHighlightWhiteBlue',
        className
      )}
      data-variant={variant}
      data-default-pad-y={defaultPadY}
      {...restProps}
    >
      {preloadBackground && hasBg && backgroundNoCss && (
        <Head>
          {mobileMediaUrl && (
            <link
              rel="preload"
              href={mobileMediaUrl}
              as="image"
              media={`(max-width: ${maxBreakpoints.md - 1}px)`}
            />
          )}
          {middleMediaUrl && (
            <link
              rel="preload"
              href={middleMediaUrl}
              as="image"
              media={`(min-width: ${maxBreakpoints.md}px) and (max-width: ${
                maxBreakpoints.lg - 1
              }px)`}
            />
          )}
          {mediaUrl && (
            <link
              rel="preload"
              href={mediaUrl}
              as="image"
              media={`(min-width: ${maxBreakpoints.lg}px)`}
            />
          )}
        </Head>
      )}

      {showPattern && (
        <span
          className={
            // styles['achieve-pattern-container']
            'box-border block overflow-hidden w-[initial] h-[initial] opacity-100 absolute m-0 p-0 border-0 inset-0 bg-transparent'
          }
        >
          <APattern
            title="Achieve pattern"
            className={
              // styles['achieve-pattern']
              'opacity-5 scale-125'
            }
          />
        </span>
      )}
      {/* Only display the a full background image when present and background pattern is disabled */}
      {!showPattern && hasBg && !backgroundNoCss && (
        <>
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              // styles['achieve-section-bg-img'],
              // !full ? styles['achieve-section-bg-img-sm']
              // : styles['achieve-section-bg-img-sm-full']
              'absolute bg-cover inset-0',
              `${!full ? 'md:hidden block h-[345px] ' : 'lg:hidden block'}`
            )}
            style={{
              backgroundImage: `url(${rewriteAssetUrl(mobileMediaUrl)})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              // styles['achieve-section-bg-img'],
              // !full ? styles['achieve-section-bg-img-md'] : styles['achieve-section-bg-img-md-full']
              'hidden absolute bg-cover inset-0',
              `${!full ? 'md:block md:h-[345px] lg:hidden' : 'md:block lg:hidden'}`
            )}
            style={{
              backgroundImage: `url(${rewriteAssetUrl(middleMediaUrl)})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
          <div
            role="img"
            aria-label={backgroundImageAlt}
            className={classNames(
              // styles['achieve-section-bg-img'],
              // styles['achieve-section-bg-img-lg']
              'hidden absolute bg-cover inset-0',
              'md:hidden lg:block'
            )}
            style={{
              backgroundImage: `url(${rewriteAssetUrl(mediaUrl)})`,
              backgroundPosition: backgroundObjectPosition,
            }}
          />
        </>
      )}

      {!showPattern && hasBg && backgroundNoCss && (
        <>
          <div
            aria-label={backgroundImageAlt}
            className={
              classNames(
                'hidden absolute bg-cover inset-0',
                '!block h-[345px]',
                'md:hidden',
                'lg:hidden'
              )
              // styles['achieve-section-bg-img'],
              // styles['achieve-section-bg-img-sm']
            }
          >
            <MediaImageStandard
              content={mobileMediaContent}
              layout="fill"
              objectFit="cover"
              alt={backgroundImageAlt}
              objectPosition={backgroundObjectPosition}
              priority={preloadBackground}
            />
          </div>
          <div
            aria-label={backgroundImageAlt}
            className={classNames(
              // styles['achieve-section-bg-img'],
              // styles['achieve-section-bg-img-md']
              'hidden absolute bg-cover inset-0',
              'md:!block md:h-[345px]',
              'lg:!hidden'
            )}
          >
            <MediaImageStandard
              content={middleMediaContent}
              layout="fill"
              objectFit="cover"
              alt={backgroundImageAlt}
              objectPosition={backgroundObjectPosition}
              priority={preloadBackground}
            />
          </div>
          <div
            aria-label={backgroundImageAlt}
            className={
              classNames('hidden absolute bg-cover inset-0', 'md:!hidden lg:!block')
              // styles['achieve-section-bg-img'],
              // styles['achieve-section-bg-img-lg']
            }
          >
            <MediaImageStandard
              content={mediaContent}
              layout="fill"
              objectFit="cover"
              alt={backgroundImageAlt}
              objectPosition={backgroundObjectPosition}
              priority={preloadBackground}
            />
          </div>
        </>
      )}
      {contents}
    </section>
  )
}

export default Section
